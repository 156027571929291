import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import ImageSideContainer from "../../components/image-side-container";
import { Button } from "react-bootstrap";
import Faq from "../../components/faq-accordian";
import MotForm from "../../components/mot-form";
import { GatsbyImage } from "gatsby-plugin-image";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const TyresPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviewAuthor
				reviewDate
				reviewContent
			}
			schemaLogo: file(relativePath: { eq: "icon.png" }) {
				publicURL
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "tyres" }) {
				slug
				carTyresFields {
					carTyresBanner {
						carTyresBannerHeading
						carTyresBannerBackgroundImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					carTyresContentSection {
						carTyresContentHeading
						carTyresContent
						brakesContentButton {
							title
							target
							url
						}
					}
					carTyresForm {
						carTyresFormHeading
					}
					carTyresLeftImageRightContent {
						carTyresRightContentHeading
						carTyresRightContentText
						carTyresRightContentButton {
							title
							target
							url
						}
						carTyresLeftImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					carTyresFaqSection {
						carTyresFaqSectionHeading
						carTyresFaqSectionDescription
						carTyresFaqs {
							carTyresAnswer
							carTyresQuestion
						}
					}
					carTyresNormalContent {
						carTyresContentWithHeading
						carTyresBookYourCheck {
							title
							target
							url
						}
					}
					carTyresReviews {
						carTyresReviewsHeading
					}
					carTyresTalkToUs {
						carTyresTalkToUsContentHeading
						carTyresTalkToUsContentText
						carTyresTalkToUsContentButton {
							title
							target
							url
						}
						carTyresTalkToUsImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					carTyresServicesCards {
						carTyresServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
						carTyresServicesCardsHeading
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, carTyresFields, slug },
	} = data;

	const {
		carTyresBanner,
		carTyresContentSection,
		carTyresForm,
		carTyresLeftImageRightContent,
		carTyresTalkToUs,
		carTyresNormalContent,
		carTyresReviews,
		carTyresFaqSection,
		carTyresServicesCards,
	} = carTyresFields;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		description: `${seoFields?.metaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},

		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Tyres",
				item: {
					url: `${siteUrl}/what-we-do/tyres`,
					id: `${siteUrl}/what-we-do/tyres`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{carTyresBanner && (
					<section>
						<ServiceHero
							title={carTyresBanner.carTyresBannerHeading}
							backgroundImage={
								carTyresBanner.carTyresBannerBackgroundImage.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt={
								carTyresBanner.carTyresBannerBackgroundImage.node?.altText
							}
						/>
					</section>
				)}

				<section className="pt-5  pt-lg-8 position-relative">
					<Container>
						{carTyresContentSection &&
							!checkPropertiesForNull(carTyresContentSection, [
								"carTyresContentHeading",
							]) && (
								<>
									<Row>
										<Col className="position-relative">
											<h2 className="text-center pb-5">
												{carTyresContentSection.carTyresContentHeading}
											</h2>
										</Col>
									</Row>
									<Row>
										<Col>
											<SafeHtmlParser
												htmlContent={carTyresContentSection?.carTyresContent}
											/>
											{carTyresContentSection.brakesContentButton &&
												carTyresContentSection.brakesContentButton.url && (
													<Button
														style={{ borderRadius: "0px" }}
														variant="secondary"
														as={Link}
														to={carTyresContentSection.brakesContentButton.url}
														className="py-3 w-100 w-md-auto mt-3 mb-4  px-5 me-4"
														target={
															carTyresContentSection.brakesContentButton
																.target ?? "_self"
														}
													>
														{carTyresContentSection.brakesContentButton.title}
													</Button>
												)}
										</Col>
									</Row>
								</>
							)}
						{carTyresLeftImageRightContent &&
							!checkPropertiesForNull(carTyresLeftImageRightContent, [
								"carTyresRightContentText",
							]) && (
								<Row>
									<Col>
										<Row className="g-5 pt-4  py-lg-5">
											<Col lg={6}>
												{" "}
												<GatsbyImage
													className="w-100 h-100"
													image={
														carTyresLeftImageRightContent.carTyresLeftImage.node
															?.localFile.childImageSharp.gatsbyImageData
													}
													alt={
														carTyresLeftImageRightContent.carTyresLeftImage.node
															?.altText
													}
												/>
											</Col>

											<Col className="py-lg-5" lg={6}>
												<SafeHtmlParser
													htmlContent={
														carTyresLeftImageRightContent?.carTyresRightContentText
													}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							)}
						{carTyresNormalContent &&
							!checkPropertiesForNull(carTyresNormalContent, [
								"carTyresContentWithHeading",
							]) && (
								<>
									<Row>
										<Col>
											<SafeHtmlParser
												htmlContent={
													carTyresNormalContent?.carTyresContentWithHeading
												}
											/>
										</Col>
									</Row>
									{carTyresNormalContent.carTyresBookYourCheck &&
										carTyresNormalContent.carTyresBookYourCheck.url && (
											<Row>
												<Col className="text-center text-md-start">
													<Button
														style={{ borderRadius: "0px" }}
														variant="secondary"
														as={Link}
														to={carTyresNormalContent.carTyresBookYourCheck.url}
														className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
														target={
															carTyresNormalContent.carTyresBookYourCheck
																.target ?? "_self"
														}
													>
														{carTyresNormalContent.carTyresBookYourCheck.title}
													</Button>
												</Col>
											</Row>
										)}
								</>
							)}
					</Container>
				</section>

				{carTyresTalkToUs &&
					!checkPropertiesForNull(carTyresTalkToUs, [
						"carTyresTalkToUsContentHeading",
					]) && (
						<div className="py-5 py-lg-8">
							<ImageSideContainer
								image={
									carTyresTalkToUs.carTyresTalkToUsImage.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								imageAlt={carTyresTalkToUs.carTyresTalkToUsImage.node?.altText}
								title={carTyresTalkToUs.carTyresTalkToUsContentHeading}
								order="first"
								imagePadding=" pb-5 pb-lg-0    "
								text={carTyresTalkToUs.carTyresTalkToUsContentText}
								ctaButton={carTyresTalkToUs?.carTyresTalkToUsContentButton}
							/>
						</div>
					)}
				{carTyresFaqSection &&
					!checkPropertiesForNull(carTyresFaqSection, [
						"carTyresFaqSectionHeading",
						"carTyresFaqs",
					]) && (
						<section className="py-5 py-lg-8 bg-background">
							<Container className="">
								<Row className="justify-content-center">
									<Col lg={10} className="text-center pb-6">
										<h2 className="text-white pb-4">
											{carTyresFaqSection.carTyresFaqSectionHeading}
										</h2>
										<p className="text-white">
											<SafeHtmlParser
												htmlContent={
													carTyresFaqSection?.carTyresFaqSectionDescription
												}
											/>
										</p>
									</Col>
								</Row>
								{carTyresFaqSection.carTyresFaqs &&
									carTyresFaqSection.carTyresFaqs.length > 0 && (
										<Row>
											{carTyresFaqSection.carTyresFaqs.map((faqItem) => (
												<Faq
													question={faqItem.carTyresQuestion}
													answer={
														<SafeHtmlParser
															htmlContent={faqItem?.carTyresAnswer}
														/>
													}
												/>
											))}
										</Row>
									)}
							</Container>
						</section>
					)}
				<section>
					<Reviews title={carTyresReviews?.carTyresReviewsHeading} />
				</section>
				<section id="book" className="pb-5  pt-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h2 className="text-center pb-4">
									{carTyresForm?.carTyresFormHeading || "BOOK YOUR APPOINTMENT"}
								</h2>
								<div
									style={{ background: "#F9F9F9", borderRadius: "18px" }}
									className="p-md-5 p-4"
								>
									<MotForm page="Car Tyres Eastbourne" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* {carTyresServicesCards &&
          !checkPropertiesForNull(carTyresServicesCards, [
            "carTyresServicesCardsHeading",
            "carTyresServicesCards",
          ]) && (
            <OurServicesSection
              title={carTyresServicesCards.carTyresServicesCardsHeading}
              services={carTyresServicesCards.carTyresServicesCards?.nodes}
            />
          )} */}
			</Layout>
		</>
	);
};

export default TyresPage;
